import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Client, ClientResponse } from '../models/clientResponse';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  readonly #URL = `${environment.apiUrl}/clients`;
  readonly #http = inject(HttpClient);

  partners = signal<Client[]>([]);

  clientsSignal = signal<Client[]>([]);
  getClients() {
    return this.#http
      .get<ClientResponse>(this.#URL + '?page=1&perPage=100')
      .pipe(tap((e) => this.clientsSignal.set(e.data.data)));
  }

  create(body: Partial<Client>) {
    return this.#http.post<{
      success: boolean;
      data: {
        name: string;
        code: string;
        partner_id: number;
        id: number;
        logo_url: null | string;
      };
      message: string;
    }>(this.#URL, body);
  }

  delete(id: number) {
    return this.#http.delete(`${this.#URL}/${id}`);
  }

  update(body: any) {
    const obj = {
      name: body.name,
      code: body.code,
      logo: body.logo,
      partner: Number(body.partner_id),
    };

    return this.#http.put<{
      data: {
        code: string;
        id: number;
        last_project_date?: number;
        last_project_number?: number;
        logo_filename?: string;
        logo_url?: string;
        name: string;
        partner_id: number;
      };
      success: boolean;
      message: string;
    }>(`${this.#URL}/${body.id}`, obj);
  }
}
