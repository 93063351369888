import { JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { CreateProjectParams } from '../../../../core/models/admin-project';
import { Client } from '../../../../core/models/clientResponse';
import { Currency } from '../../../../core/models/currency';
import { UserPostParam } from '../../../../core/models/user-post-param.model';
import { ClientsService } from '../../../../core/services/clients.service';
import { ErrorHandlerService } from '../../../../core/services/error-handler.service';
import { PartnerService } from '../../../../core/services/partners.service';
import { ProjectService } from '../../../../core/services/project.service';
import { UsersService } from '../../../../core/services/users.service';
import { AddSkipperFormComponent } from '../../../../shared/ui-components/add-skipper-form/add-skipper-form.component';
import { Skipper } from './../../../../core/models/skipper.model';

@Component({
  selector: 'app-create-project',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDialogModule,
    JsonPipe,
    AddSkipperFormComponent,
  ],
  templateUrl: './create-project.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProjectComponent implements OnInit {
  clientservice = inject(ClientsService);
  partnerService = inject(PartnerService);
  dialogRef = inject(MatDialogRef<CreateProjectComponent>);
  projectService = inject(ProjectService);
  fb = inject(UntypedFormBuilder);
  errorHandlerService = inject(ErrorHandlerService);
  destroyRef = inject(DestroyRef);
  translateService = inject(TranslateService);
  userService = inject(UsersService);

  stepper = viewChild<MatStepper>('stepper');

  // Signals
  partnerStepName = signal<string | null>(null);
  clientStepName = signal<string | null>(null);
  skipperStepName = signal<string | null>(null);
  skippers = signal<Skipper[]>([]);
  currency = signal<Currency[]>([]);

  projectName = signal<string | null>(null);
  description = signal<string | null>(null);
  SkipperName = signal<string | null>(null);
  currencyName = signal<string | null>(null);
  startDate = signal<string | null>(null);
  endDate = signal<string | null>(null);
  imageUrl = signal<string | null>(null);
  filteredClients = signal<Client[]>([]);

  createPartner = signal<boolean>(false);
  createClient = signal<boolean>(false);
  createSkipper = signal<boolean>(false);

  modalData = toSignal(
    forkJoin([
      this.clientservice.getClients(),
      this.partnerService.getPartners(),
    ]),
  );

  clients = this.clientservice.clientsSignal;
  parnters = this.partnerService.partnersSignal;

  // Form groups
  paternerForm = this.fb.group({
    paternerId: [null, Validators.required],
    name: [null],
    description: [null],
    logo: [null],
  });

  clientForm = this.fb.group({
    clientId: [null, Validators.required],
    name: [null],
    code: [null],
    logo: [null],
  });

  projectForm = this.fb.group({
    projectName: [null, Validators.required],
    description: [null, Validators.required],
    skipperId: [null],
    currencyId: [null],
    username: [null],
    firstname: [null],
    lastname: [null],
    email: [null],
    password: { value: null, disabled: true },
  });

  dateForm = this.fb.group({
    start: [null, Validators.required],
    end: [null],
  });

  // Methods
  setPartners() {
    const id = this.paternerForm.get('paternerId')?.value;
    const partner = this.parnters().find((p) => p.id === Number(id));
    if (partner?.name) {
      this.partnerStepName.set(partner.name);
    }
  }

  addNewPartner() {
    this.paternerForm.get('paternerId')?.setValue(null);
    this.partnerStepName.set(
      this.translateService.instant('project-admin.action.addPartner'),
    );
    this.createPartner.set(true);
    this.paternerForm.get('paternerId')?.clearValidators();
    this.paternerForm.get('paternerId')?.updateValueAndValidity();
    this.paternerForm.get('name')?.setValidators(Validators.required);
    this.paternerForm.get('name')?.updateValueAndValidity();
  }

  addSkipperFormButton() {
    this.createSkipper.set(true);
    this.skipperStepName.set(
      this.translateService.instant('project-admin.action.addSkipper'),
    );

    this.projectForm
      .get('password')
      ?.setValue(this.userService.generateStrongPassword());

    this.projectForm.get('projectName')?.clearValidators();
    this.projectForm.get('description')?.clearValidators();

    this.projectForm.get('username')?.setValidators(Validators.required);
    this.projectForm.get('firstname')?.setValidators(Validators.required);
    this.projectForm.get('lastname')?.setValidators(Validators.required);
    this.projectForm.get('email')?.setValidators(Validators.required);

    this.projectForm.get('projectName')?.updateValueAndValidity();
    this.projectForm.get('description')?.updateValueAndValidity();
  }

  createNewSkipper() {
    const newSkipper: UserPostParam = {
      active: 1,
      email: this.projectForm.get('email')?.value,
      firstname: this.projectForm.get('firstname')?.value,
      lastname: this.projectForm.get('lastname')?.value,
      partner: this.paternerForm.get('paternerId')?.value,
      username: this.projectForm.get('username')?.value,
      password: this.projectForm.get('password')?.value,
      password_confirm: this.projectForm.get('password')?.value,
    };

    this.userService
      .create(newSkipper, 'skippers')
      .pipe(
        tap((res: any) => {
          this.skippers.update((prev) => [...prev, res.data]);
          this.cancelCreationOfSkipper();
          this.projectForm.get('skipperId')?.setValue(res.data.id);
        }),
        catchError((err) => {
          this.errorHandlerService.handleError(err.message);
          return of([]);
        }),
      )
      .subscribe();
  }

  cancelCreationOfSkipper() {
    this.createSkipper.set(false);
    this.skipperStepName.set(null);

    this.projectForm.get('projectName')?.setValidators(Validators.required);
    this.projectForm.get('description')?.setValidators(Validators.required);

    this.projectForm.get('username')?.clearValidators();
    this.projectForm.get('firstname')?.clearValidators();
    this.projectForm.get('lastname')?.clearValidators();
    this.projectForm.get('email')?.clearValidators();

    this.projectForm.updateValueAndValidity();
  }

  addNewClient() {
    this.clientForm.get('clientId')?.setValue(null);
    this.clientStepName.set(
      this.translateService.instant('project-admin.action.addClient'),
    );
    this.createClient.set(true);
    this.clientForm.get('clientId')?.clearValidators();
    this.clientForm.get('clientId')?.updateValueAndValidity();
    this.clientForm.get('name')?.setValidators(Validators.required);
    this.clientForm.get('name')?.updateValueAndValidity();
  }

  cancelCreationOfPartner() {
    this.createPartner.set(false);
    this.partnerStepName.set(null);
    this.paternerForm.get('name')?.clearValidators();
    this.paternerForm.get('name')?.updateValueAndValidity();
    this.paternerForm.get('paternerId')?.setValidators(Validators.required);
    this.paternerForm.get('paternerId')?.updateValueAndValidity();
  }

  cancelCreationOfClient() {
    this.createClient.set(false);
    this.clientStepName.set(null);
    this.clientForm.get('name')?.clearValidators();
    this.clientForm.get('name')?.updateValueAndValidity();
    this.clientForm.get('clientId')?.setValidators(Validators.required);
    this.clientForm.get('clientId')?.updateValueAndValidity();
  }

  createNewPartner() {
    this.partnerService?.create(this.paternerForm.value).subscribe((res) => {
      this.parnters.update((prev) =>
        [...prev, res.data].sort((a, b) => a.name.localeCompare(b.name)),
      );
      this.cancelCreationOfPartner();
      this.paternerForm.get('paternerId')?.setValue(res.data.id);
      this.partnerStepName.set(res.data.name);
    });

    this.projectForm.patchValue({
      currencyId: 45,
    });

    this.imageUrl.set(null);
  }

  createNewClient() {
    const client = {
      name: this.clientForm.get('name')?.value,
      code: this.clientForm.get('code')?.value,
      partner: this.paternerForm.get('paternerId')?.value,
      logo: this.clientForm.get('logo')?.value,
    };
    this.clientservice.create(client).subscribe((res) => {
      this.clients.update((prev) =>
        [...prev, res.data as Client].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      );
      this.cancelCreationOfPartner();
      this.clientForm.get('clientId')?.setValue(res.data.id);
      this.clientStepName.set(res.data.name);
      this.imageUrl.set(null);
    });
  }

  setClientName() {
    const id = this.clientForm.get('clientId')?.value;
    const client = this.clients().find((client) => client.id === Number(id));
    if (client?.name) {
      this.clientStepName.set(client.name);
      this.projectService
        .getDataForAddProject(id)
        .pipe(
          tap((res) => {
            this.skippers.set(res.data.skippers);
            this.currency.set(res.data.currencies);
          }),
          takeUntilDestroyed(this.destroyRef),
        )

        .subscribe();
    }
  }

  setProject() {
    const { projectName, description, skipperId, currencyId } =
      this.projectForm.value;
    this.projectName.set(projectName);
    this.description.set(description);
    const skipper: Skipper | null =
      this.skippers().find((s) => s.id === skipperId) ?? null;
    const currencyName = this.currency().find((c) => c.id === currencyId)?.name;
    if (skipper) {
      this.SkipperName.set(`${skipper.firstname} ${skipper.lastname}`);
    }
    this.currencyName.set(currencyName ?? null);
  }

  setDate() {
    const { start, end } = this.dateForm.value;
    this.startDate.set(start);
    this.endDate.set(end);
  }

  ngOnInit(): void {
    this.paternerForm
      .get('paternerId')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        this.stepper()?.steps.get(2)?.reset();
        this.stepper()?.steps.get(3)?.reset();
        this.stepper()?.steps.get(4)?.reset();
        this.stepper()?.steps.last.reset();

        if (x) {
          const clients = this.clients() ?? [];
          const filtered = clients.filter(
            (client) => client.partner_id === Number(x),
          );
          this.filteredClients.set(filtered);
          if (this.filteredClients().length === 0) {
            this.addNewClient();
          } else {
            this.cancelCreationOfClient();
          }
        }
      });
  }

  confirmProjectCreation() {
    const { start, end } = this.dateForm.value;
    const newProject: CreateProjectParams = {
      name: this.projectName() ?? '',
      description: this.description() ?? '',
      client: this.clientForm.get('clientId')?.value,
      skipper: this.projectForm.get('skipperId')?.value,
      currency: this.projectForm.get('currencyId')?.value,
      start_date: start,
      end_date: end,
      closed: 0,
    };

    this.projectService
      .createProject(newProject)
      .pipe(
        tap((res) => {
          this.dialogRef.close(res);
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageUrl.set(reader.result as string);
        this.paternerForm.patchValue({
          logo: this.imageUrl(),
        });
      };
    }
  }

  removeImage() {
    this.imageUrl.set(null);
    this.paternerForm.patchValue({
      logo: null,
    });
  }

  onStepChange(event: any) {
    this.updateData();
  }

  updateData() {
    this.setClientName();
    this.setPartners();
    this.setProject();
    this.setDate();
  }
}
