import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  AddPartnerResponse,
  Partner,
  PartnerResponse,
  UpdatePartnerResponse,
} from '../models/partnersResponse';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  readonly #URL = `${environment.apiUrl}/partners`;
  readonly #http = inject(HttpClient);

  partnersSignal = signal<Partner[]>([]);
  getPartners() {
    return this.#http
      .get<PartnerResponse>(this.#URL + '?page=1&perPage=100')
      .pipe(tap((e) => this.partnersSignal.set(e.data.data)));
  }

  create(body: Partial<Partner>) {
    return this.#http.post<AddPartnerResponse>(this.#URL, body);
  }

  delete(id: number) {
    return this.#http.delete(`${this.#URL}/${id}`);
  }

  update(body: Partial<Partner>) {
    const id = body.id;
    let obj = {
      name: body.name,
      description: body.description,
      logo: body.logo_url ?? null,
      license_number: body.license_number,
      min_project_duration: body.min_project_duration,
    };

    return this.#http.put<UpdatePartnerResponse>(`${this.#URL}/${id}`, obj);
  }
}
