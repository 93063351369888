<div class="flex flex-col modal-content rounded-b-2xl" #modalContainer>
  <div class="bg-white rounded-2xl overflow-x-auto shadow-lg">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl font-bold" mat-dialog-title>
        {{ "project-admin.newProjectTitle" | translate }}
      </h1>

      <button (click)="dialogRef.close()" class="pr-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <mat-dialog-content>
      <mat-vertical-stepper
        [linear]="true"
        #stepper
        (selectionChange)="onStepChange($event)"
      >
        <!--STEP 1 -->
        <mat-step [stepControl]="paternerForm">
          <form [formGroup]="paternerForm">
            <ng-template matStepLabel>
              {{
                partnerStepName() ??
                  "project-admin.createProjectModal.selectPartner" | translate
              }}</ng-template
            >
            @if (!createPartner()) {
              <div class="mb-3">
                <label class="form-control">
                  <span class="text-base font-semibold required">{{
                    "project-admin.createProjectModal.partnerName" | translate
                  }}</span>
                  <select
                    formControlName="paternerId"
                    class="select select-bordered bg-white focus:outline-0 focus:border-primary"
                  >
                    @for (data of parnters(); track data.id) {
                      <option [value]="data.id">
                        {{ data.name }}
                      </option>
                    }
                  </select>
                </label>
              </div>
            } @else {
              <div class="mb-3 pt-4">
                @if (paternerForm.get("logo")?.value) {
                  <div class="indicator">
                    <span
                      class="indicator-item badge badge-error p-2"
                      (click)="removeImage()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                    <img [src]="imageUrl()" class="max-w-28 object-contain" />
                  </div>
                } @else {
                  <label
                    class="btn btn-square btn-sm btn-outline btn-secondary"
                    for="selectImg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="selectImg"
                    class="invisible w-0"
                    accept="image/png, image/gif, image/jpeg"
                    formControlName="logo"
                    (change)="onFileSelected($event)"
                  />
                }
                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">{{
                      "project-admin.partnerTable.partnerName" | translate
                    }}</span>
                  </div>
                  <input
                    type="text"
                    class="input input-bordered bg-white focus:outline-0 focus:border-black"
                    formControlName="name"
                  />
                </label>

                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">{{
                      "project-admin.partnerTable.description" | translate
                    }}</span>
                  </div>
                  <input
                    type="text"
                    class="input input-bordered bg-white focus:outline-0 focus:border-black"
                    formControlName="description"
                  />
                </label>
              </div>
            }

            <div class="flex gap-2 flex-wrap">
              <button
                [disabled]="!paternerForm.valid"
                class="btn btn-outline btn-primary hover:text-white"
                matStepperNext
                (click)="createPartner() ? createNewPartner() : setPartners()"
              >
                @if (!createPartner()) {
                  {{ "project-admin.createProjectModal.next" | translate }}
                } @else {
                  {{ "common.create" | translate }}
                }
              </button>

              @if (!createPartner()) {
                <button
                  class="btn btn-active btn-link"
                  (click)="addNewPartner()"
                >
                  {{ "project-admin.newPartner" | translate }}
                </button>
              } @else {
                <button
                  class="link link-error"
                  (click)="cancelCreationOfPartner()"
                >
                  {{ "common.cancel" | translate }}
                </button>
              }
            </div>
          </form>
        </mat-step>

        <!--STEP 2-->
        <mat-step [stepControl]="clientForm">
          <form [formGroup]="clientForm">
            <ng-template matStepLabel>
              {{
                clientStepName() ??
                  "project-admin.createProjectModal.selectClient" | translate
              }}
            </ng-template>

            @if (createClient()) {
              <div class="mb-3 pt-4">
                @if (clientForm.get("logo")?.value) {
                  <div class="indicator">
                    <span
                      class="indicator-item badge badge-error p-2"
                      (click)="removeImage()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                    <img [src]="imageUrl()" class="max-w-28 object-contain" />
                  </div>
                } @else {
                  <label
                    class="btn btn-square btn-sm btn-outline btn-secondary"
                    for="selectImg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="selectImg"
                    class="invisible w-0"
                    accept="image/png, image/gif, image/jpeg"
                    formControlName="logo"
                    (change)="onFileSelected($event)"
                  />
                }
                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">{{
                      "project-admin.createProjectModal.clientName" | translate
                    }}</span>
                  </div>
                  <input
                    type="text"
                    class="input input-bordered bg-white focus:outline-0 focus:border-black"
                    formControlName="name"
                  />
                </label>

                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">{{
                      "project-admin.clientsTable.code" | translate
                    }}</span>
                  </div>
                  <input
                    type="text"
                    class="input input-bordered bg-white focus:outline-0 focus:border-black"
                    formControlName="code"
                  />
                </label>
              </div>
            } @else {
              <div class="mb-3">
                <label class="form-control">
                  <span class="text-base font-semibold required">{{
                    "project-admin.createProjectModal.clientName" | translate
                  }}</span>
                  <select
                    formControlName="clientId"
                    class="select select-bordered bg-white focus:outline-0 focus:border-primary"
                    [attr.placeholder]="
                      'project-admin.clientNameLabel' | translate
                    "
                  >
                    @for (item of filteredClients(); track item.id) {
                      <option [value]="item.id">
                        {{ item.name }}
                      </option>
                    }
                  </select>
                </label>
              </div>
            }

            <div class="flex gap-2 flex-wrap">
              <button
                [disabled]="!clientForm.valid"
                class="btn btn-outline btn-primary hover:text-white"
                matStepperNext
                (click)="createClient() ? createNewClient() : setClientName()"
              >
                @if (!createClient()) {
                  {{ "project-admin.createProjectModal.next" | translate }}
                } @else {
                  {{ "common.create" | translate }}
                }
              </button>

              @if (!createClient()) {
                <button
                  class="btn btn-active btn-link"
                  (click)="addNewClient()"
                >
                  {{ "project-admin.action.addClient" | translate }}
                </button>
              } @else {
                <button
                  class="link link-error"
                  (click)="cancelCreationOfClient()"
                >
                  {{ "common.cancel" | translate }}
                </button>
              }
            </div>
          </form>
        </mat-step>

        <!-- STEP 3 -->
        <mat-step [stepControl]="projectForm">
          <form [formGroup]="projectForm">
            <ng-template matStepLabel>
              @if (skipperStepName()) {
                {{ skipperStepName() }}
              } @else {
                {{
                  "project-admin.createProjectModal.projectDescription"
                    | translate
                }}
              }
            </ng-template>
            @if (!createSkipper()) {
              <div class="mb-3">
                <span class="text-base font-semibold required">{{
                  "project-admin.createProjectModal.name"
                    | translate
                    | translate
                }}</span>

                <input
                  formControlName="projectName"
                  type="text"
                  class="input input-bordered w-full bg-white focus:outline-0 focus:border-primary"
                />
              </div>

              <div class="mb-3">
                <span class="required text-base font-semibold">{{
                  "project-admin.createProjectModal.description" | translate
                }}</span>

                <textarea
                  formControlName="description"
                  class="textarea textarea-bordered textarea-sm w-full bg-white focus:outline-0 focus:border-primary"
                  rows="3"
                  placeholder="..."
                ></textarea>
              </div>

              <div class="mb-3">
                <label class="form-control">
                  <span class="text-base font-semibold">{{
                    "project-admin.createProjectModal.skipper" | translate
                  }}</span>
                  <select
                    formControlName="skipperId"
                    class="select select-bordered bg-white focus:outline-0 focus:border-primary"
                    [attr.placeholder]="
                      'project-admin.clientNameLabel' | translate
                    "
                  >
                    <option value="null">-</option>
                    @for (item of skippers(); track item.id) {
                      <option [value]="item.id">
                        {{ item.firstname }} {{ item.lastname }}
                      </option>
                    }
                  </select>
                </label>
              </div>
            } @else {
              <label class="form-control w-full max-w-xs">
                <div class="label">
                  <span class="label-text required">{{
                    "project-admin.table.id" | translate
                  }}</span>
                </div>
                <input
                  type="text"
                  class="input input-bordered bg-white focus:outline-0 focus:border-black"
                  formControlName="username"
                />
              </label>

              <label class="form-control w-full max-w-xs">
                <div class="label">
                  <span class="label-text required">{{
                    "project-admin.table.firstname" | translate
                  }}</span>
                </div>
                <input
                  type="text"
                  class="input input-bordered bg-white focus:outline-0 focus:border-black"
                  formControlName="firstname"
                />
              </label>

              <label class="form-control w-full max-w-xs">
                <div class="label">
                  <span class="label-text required">{{
                    "project-admin.table.familyname" | translate
                  }}</span>
                </div>
                <input
                  type="text"
                  class="input input-bordered bg-white focus:outline-0 focus:border-black"
                  formControlName="lastname"
                />
              </label>

              <label class="form-control w-full max-w-xs">
                <div class="label">
                  <span class="label-text required">{{
                    "project-admin.table.email" | translate
                  }}</span>
                </div>
                <input
                  type="email"
                  class="input input-bordered bg-white focus:outline-0 focus:border-black"
                  formControlName="email"
                />
              </label>

              <label class="form-control mb-3 w-full max-w-xs">
                <div class="label">
                  <span class="label-text">{{
                    "project-admin.table.password" | translate
                  }}</span>
                </div>
                <input
                  type="text"
                  class="input input-bordered bg-white focus:outline-0 focus:border-black"
                  formControlName="password"
                  [disabled]="true"
                />
              </label>
            }

            <div class="flex gap-2">
              @if (createSkipper()) {
                <button
                  class="btn btn-outline btn-primary hover:text-white"
                  [disabled]="!projectForm.valid"
                  (click)="createNewSkipper()"
                >
                  {{ "common.create" | translate }}
                </button>

                <button
                  class="link link-error"
                  (click)="cancelCreationOfSkipper()"
                >
                  {{ "common.cancel" | translate }}
                </button>
              } @else {
                <button
                  class="btn btn-outline btn-primary hover:text-white"
                  matStepperNext
                  [disabled]="!projectForm.valid"
                  (click)="setProject()"
                >
                  {{ "project-admin.createProjectModal.next" | translate }}
                </button>

                <button
                  class="btn btn-active btn-link"
                  (click)="addSkipperFormButton()"
                >
                  {{ "project-admin.newSkipper" | translate }}
                </button>
              }
            </div>
          </form>
        </mat-step>

        <!--STEP 4 -->

        <mat-step [stepControl]="dateForm">
          <form [formGroup]="dateForm">
            <ng-template matStepLabel>
              {{
                "project-admin.createProjectModal.projectPeriod" | translate
              }}</ng-template
            >

            <div class="mb-3">
              <span class="text-base font-semibold required">{{
                "project-admin.createProjectModal.startDate" | translate
              }}</span>

              <input
                formControlName="start"
                type="date"
                class="input input-bordered w-full bg-white focus:outline-0 focus:border-primary"
              />
            </div>

            <div class="mb-3">
              <span class="text-base font-semibold">{{
                "project-admin.createProjectModal.endDate" | translate
              }}</span>

              <input
                formControlName="end"
                type="date"
                class="input input-bordered w-full bg-white focus:outline-0 focus:border-primary"
              />
            </div>

            <div>
              <button
                (click)="setDate()"
                class="btn btn-outline btn-primary hover:text-white"
                matStepperNext
                [disabled]="!dateForm.valid"
              >
                {{ "project-admin.createProjectModal.next" | translate }}
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step (click)="updateData()">
          <ng-template matStepLabel>
            {{
              "project-admin.createProjectModal.summary" | translate
            }}</ng-template
          >

          <div class="flex flex-col">
            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.partner" | translate
                }}:</span
              >
              {{ partnerStepName() }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.client" | translate
                }}:</span
              >
              {{ clientStepName() }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.name" | translate
                }}:</span
              >
              {{ projectName() }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.description" | translate
                }}:</span
              >
              {{ description() }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.skipper" | translate
                }}:</span
              >
              {{ SkipperName() == null ? "-" : SkipperName() }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.startDate" | translate
                }}:</span
              >
              {{ dateForm.get("start")?.value }}
            </div>

            <div class="mb-2">
              <span class="font-bold"
                >{{
                  "project-admin.createProjectModal.endDate" | translate
                }}:</span
              >
              {{ dateForm.get("end")?.value ?? "-" }}
            </div>
          </div>

          <div class="flex justify-between">
            <button
              mat-button
              matStepperPrevious
              class="btn btn-outline btn-error"
            >
              {{ "common.cancel" | translate }}
            </button>
            <button
              mat-button
              (click)="confirmProjectCreation()"
              class="btn btn-primary text-white"
            >
              {{ "project-admin.createProjectModal.createProject" | translate }}
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </mat-dialog-content>
  </div>
</div>
<style>
  :host {
    mat-vertical-stepper {
      background-color: white !important;
    }
  }
</style>
